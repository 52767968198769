export const menuList = [
  {
    title: 'Products',
    subMenus: [
      {
        title: 'AI Tools',
        subMenus: [
          {
            title: 'AI Chat',
            link: '/feature/how-to-access-gpt-4'
          },
          {
            title: 'AI Search',
            link: '/search'
          },
          {
            title: 'Data Analysis',
            link: '/arvin-code-interpreter'
          },
          {
            title: 'GPTs',
            link: '/best-gpts/arvin-gpts'
          },
          {
            title: 'More Tools',
            link: '/ai-tools'
          }
        ]
      },
      {
        title: 'Writing',
        subMenus: [
          {
            title: 'AI Article Writer',
            link: '/feature/ai-article-writer'
          },
          {
            title: 'Email Writer',
            link: '/feature/ai-email-response-generator'
          },
          {
            title: 'Writing Improver',
            link: '/feature/writing-improver'
          },
          {
            title: 'Grammar Checker',
            link: '/feature/ai-grammar-checker'
          },
          {
            title: 'Web Translator',
            link: '/feature/web-translator'
          }
        ]
      },
      {
        title: 'Images',
        subMenus: [
          {
            title: 'AI Image Generator',
            link: '/feature/ai-painter'
          },
          {
            title: 'AI Logo Designer',
            link: '/feature/ai-logo-designer'
          },
          {
            title: 'Remove Background',
            link: '/image-tools/background-remover'
          },
          {
            title: 'Change Background',
            link: '/image-tools/background-changer'
          },
          {
            title: 'Sketch to Image',
            link: '/image-tools/sketch-to-image'
          },
          {
            title: 'Image to Image',
            link: '/image-tools/image-to-image'
          },
          {
            title: 'Restore Image',
            link: '/image-tools/ai-photo-editor'
          },
          {
            title: 'Remove Text',
            link: '/image-tools/remove-text-from-image'
          },

          {
            title: 'Cleanup',
            link: '/image-tools/cleanup'
          },
          {
            title: 'Upscale',
            link: '/image-tools/image-upscaler'
          },
          {
            title: 'Uncrop',
            link: '/image-tools/uncrop'
          },
          {
            title: 'More Image Tools',
            link: '/image-tools'
          }
        ]
      },
      {
        title: 'Reading',
        subMenus: [
          {
            title: 'PDF to Word',
            link: '/PDF-tools/pdf-to-word'
          },
          {
            title: 'PDF to PNG',
            link: '/PDF-tools/pdf-to-png'
          },
          {
            title: 'PDF to JPG',
            link: '/PDF-tools/pdf-to-jpg'
          }
        ]
      }
    ]
  },
  {
    title: 'Resources',
    subMenus: [
      {
        title: 'Prompt Library',
        subMenus: [
          {
            title: 'ChatGPT Prompts',
            link: '/chatgpt-prompt-generator'
          },
          {
            title: 'GPT-4o Prompts',
            link: 'https://arvin.chat/chatgpt-prompt-generator/gpt-4-prompts/'
          },
          {
            title: 'Midjourney Prompts',
            link: 'https://arvin.chat/chatgpt-prompt-generator/chatgpt-prompts-for-midjourney/midjourney-prompt-guide/'
          }
        ]
      },
      {
        title: 'Help Center',
        subMenus: [
          {
            title: 'User Guide',
            link: '/arvin-instruction'
          },
          {
            title: 'Blog',
            link: 'https://arvin.chat/blog/'
          },
          {
            title: 'Pricing',
            link: '/user/subscription',
            hidden: true
          },
          {
            title: 'Contact Us',
            link: 'https://arvin-web.zendesk.com/hc/en-us/requests/new',
            target: '_blank',
            hidden: true
          }
          // {
          //   title: 'Feedback',
          //   link: 'mailto:feedback@arvin.chat',
          //   target: '_blank'
          // },
          // {
          //   title: 'Contact Us',
          //   link: 'mailto:contact@arvin.chat',
          //   target: '_blank'
          // }
        ]
      }
    ]
  }
];
