<template>
  <div
    :class="visible ? 'h-100vh' : 'h-0px'"
    class="transitions menu-list fixed w-100vw left-0px top-0px overflow-auto z-99 bg-#fff">
    <div class="flex-between py-24px px-18px">
      <div class="flex gap-8px items-center">
        <img src="~/assets/imgs/logo-img.png" alt="logo" class="w-32px" />
        <div class="c-#282A37 text-18px font-700">Arvin</div>
      </div>
      <div class="flex-center" @click="handleClose">
        <nuxt-icon name="close" class="fs-14 c-#616478" filled />
      </div>
    </div>
    <div class="pl-24px pr-12px pb-24px">
      <div v-for="(item, i) in menuList" :key="i" class="flex flex-col m-b-12px">
        <div class="demo-collapse">
          <el-collapse>
            <el-collapse-item :name="`main-${i}`">
              <template #title>
                <div class="fs-16 c-#232A37 font-600">
                  {{ item.title }}
                </div>
              </template>
              <div class="w-full flex items-start">
                <el-collapse class="border-none! w-full">
                  <div class="w-full flex flex-col">
                    <template v-for="(menu, index) in item.subMenus" :key="index">
                      <el-collapse-item :name="`chid-${index}`">
                        <template #title>
                          <div class="fs-14 c-#232A37 font-600 ml-18px">
                            {{ menu.title }}
                          </div>
                        </template>
                        <div v-for="link in menu.subMenus" :key="link.link" class="mb-16px">
                          <div
                            class="flex flex-col fs-14 ml-18px decoration-none">
                            <!-- <div class="font-600 c-#444658" >{{ menu.title }}</div> -->
                            <nuxt-link :key="link.link" class="decoration-none c-#282a37 hover:c-primary" :to="link.link">{{ link.title }}</nuxt-link>
                          </div>
                        </div>
                      </el-collapse-item>
                    </template>
                  </div>
                </el-collapse>
              </div>
            </el-collapse-item>
          </el-collapse>

        </div>
      </div>
      <nuxt-link
        to="/user/subscription" class="line-height-66px el-dropdown-link decoration-none c-#282a37 hover:c-primary fs-16 c-#232A37 font-600"
      >
        Pricing
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
	import { ref } from 'vue';
	const props = defineProps({
		menuList: {
			type: Array,
			default: []
		}
	});
	console.log(props.menuList);

	const visible = ref(false);
	const activeMenu = ref('');
	const expandMenu = ref('');
	const route = useRoute();
	onMounted(() => {
		activeMenu.value = route.path;
	});

	watch(
		() => route.path,
		() => {
			activeMenu.value = route.path;
		}
	);

	const open = () => {
		visible.value = true;
	};

	const handleClose = () => {
		visible.value = false;
	};

	const toggleMenu = (name: string) => {
		expandMenu.value = expandMenu.value === name ? '' : name;
	};

	defineExpose({
		open
	});
</script>

<style lang="less" scoped>
.menu-list {
  overscroll-behavior: none;
  .menu-item {
    font-size: 18px;
    font-weight: 600;
    color: #282A37;
  }
}
:deep(.el-collapse) {
  border:none;
  .el-collapse-item__header.is-active {
    border-bottom: none;
  }
  .el-collapse-item__content {
    border:none;
    padding-bottom: 0;
  }
  .el-collapse-item__wrap {
    border:none;
  }
  .el-collapse-item__header {
    border:none;
    margin-bottom: 7px;
  }
  .el-collapse-item {
    margin-top:12px;
  }
}

</style>
